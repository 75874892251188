import {StorageImage} from "@aws-amplify/ui-react-storage";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import EditIcon from "@mui/icons-material/Edit"
import {CardHeader} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {RichTreeView} from "@mui/x-tree-view/RichTreeView";
import {generateClient} from "aws-amplify/api";
import {fetchAuthSession} from "aws-amplify/auth";
import dayjs from "dayjs";
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {listBlogPosts} from "../graphql/queries";
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear);

function Blog() {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [selectedTimeFrame, setSelectedTimeFrame] = useState(0 + "," + (Math.pow(2, 31) - 1));
    const [blogPosts, setBlogPosts] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const years = useMemo(() => {
        const yearsArr = [];
        for (let i = dayjs().year() + 2; i >= 2024; --i) {
            yearsArr.push(i);
        }
        return yearsArr.map(year => {
            return {
                id: dayjs().year(year).quarter(1).startOf('quarter').unix() + "," + dayjs().year(year).quarter(4).endOf('quarter').unix(),
                label: year.toString(),
                children: [
                    {id: dayjs().year(year).quarter(4).startOf('quarter').unix() + "," + dayjs().year(year).quarter(4).endOf('quarter').unix(), label: "Q4"},
                    {id: dayjs().year(year).quarter(3).startOf('quarter').unix() + "," + dayjs().year(year).quarter(3).endOf('quarter').unix(), label: "Q3"},
                    {id: dayjs().year(year).quarter(2).startOf('quarter').unix() + "," + dayjs().year(year).quarter(2).endOf('quarter').unix(), label: "Q2"},
                    {id: dayjs().year(year).quarter(1).startOf('quarter').unix() + "," + dayjs().year(year).quarter(1).endOf('quarter').unix(), label: "Q1"}

                ]
            };
        });
    }, []);
    useEffect(() => {
        async function currentSession() {
            const {accessToken} = (await fetchAuthSession()).tokens ?? {};
            setIsAdmin(accessToken?.payload["cognito:groups"].includes("cfsAdmin"));
        }
        async function fetchData() {
            const dates = selectedTimeFrame.split(",", 2);
            const client = generateClient();
            const resp = await client.graphql({
                query: listBlogPosts,
                variables: {filter: {and: [{or: [{title: {contains: search}}, {short_description: {contains: search}}]}, {date: {between: [dates[0], dates[1]]}}]}},
                authMode: "iam"
            });
            setBlogPosts(resp.data.listBlogPosts.items);
        }
        void currentSession();
        void fetchData();
    }, [navigate, search, selectedTimeFrame]);
    return (
        <section id="blog" className="content-section">
            <div className="content entire-paragraph">
                <h1 style={{textAlign: "center"}}>Blog</h1>
                <Grid container>
                    <Grid item xs={12} lg={3}>
                        <h3>Blog Archive</h3>
                        <h4>Please disable adblockers if no blog posts appear on this page.</h4>
                        <Stack spacing={1}>
                            <TextField label="🔎 Search" variant="outlined"
                                       onChange={event => setSearch(event.target.value)}/>
                            <RichTreeView items={years}
                                          isItemDisabled={item => dayjs.unix(item.id.split(",", 2)[0]).year() > dayjs().year()}
                                          onSelectedItemsChange={(_, itemIds) => setSelectedTimeFrame(itemIds)}/>
                            <Button variant="contained" style={{fontSize: 16}} onClick={() => {
                                setSearch("");
                                setSelectedTimeFrame(0 + "," + (Math.pow(2, 31) - 1));
                            }}>
                                Clear
                            </Button>

                        </Stack>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Grid container style={{marginTop: 25}}>
                            {
                                blogPosts.sort((a, b) => b.date - a.date).map((post) => (
                                    <Grid item xs={12} lg={6} key={post.id} style={{marginBottom: 50}}>
                                        <Card className="card-post" sx={{maxWidth: 400}} orientation="vertical"
                                              size="md" variant="soft" style={{margin: "0 auto"}}>
                                            <CardHeader className="card-post" title={post.title}
                                                        subheader={dayjs.unix(post.date).format('DD/MM/YYYY')}/>
                                            <CardMedia className="card-post" sx={{height: 350, width: 400}}>
                                                <StorageImage imgKey={post.image} accessLevel="guest" alt={post.image}
                                                              style={{height: 350, width: 400}}/>
                                            </CardMedia>
                                            <CardContent className="card-post">
                                                <Typography variant="body1">
                                                    {post.short_description}
                                                </Typography>
                                            </CardContent>
                                            <CardActions className="card-post">
                                                <Button size="small" startIcon={<ReadMoreIcon/>} onClick={() =>
                                                    navigate("/blog_detail", {state: {id: post.id}})}>
                                                    Read More
                                                </Button>
                                                {
                                                    isAdmin &&
                                                    <Button size="small" startIcon={<EditIcon/>} onClick={() =>
                                                        navigate("/form", {state: {id: post.id}})}>
                                                        Edit Post
                                                    </Button>
                                                }
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}

export default Blog;
